@import url("https://fonts.googleapis.com/css2?family=Bayon&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");


*{
    margin: 0;
    padding: 0;
  }
 
  .background-image {
    background-image: linear-gradient(to right bottom, #ffffff, #f9f8ff, #f3f1fe, #eceafe, #e6e3fd);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
  }
  ::-webkit-scrollbar {
    overflow-y: scroll;
    background: none;
  }
  @font-face {
    font-family: Bayon;
    src: url("/public/assets/Bayon-Regular.ttf");
  }

  @font-face {
    font-family: Khmer-Os-Siemreap;
    src: url("/public/assets/KhmerOSsiemreap.ttf")
  }

  .KhmerOsSiemreap {
    font-family: Khmer-Os-Siemreap !important;
  }